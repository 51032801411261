<template>
  <div class="capabilities-checker" id="capabilities-checker">
    <div class="row">
      <div class="col">
        <h5 :is="printStyles ? 'h4' : 'h5'" class="my-2 d-inline">Capabilities Check</h5>
        <scaled-image class="float-right" :class="{'d-none': !printStyles}" :src="clientImgSrc('site-topbar-rightv2.png')" :height="50"/>
        <br v-if="printStyles" /><br v-if="printStyles" />
        <div class="panel-body mb-2">
          <transition name="fade">
            <div v-if="$store.getters.isLoadingWikiData" class="my-4">
              <span id="imgLoading">
                <i class="fa fa-sync fa-spin"></i>&nbsp; Getting capabilities data...
              </span>
            </div>
            <div v-else-if="$store.getters.localPolicies.length === 0" class="py-3">
              <div class="wiki-data-not-found">
                <strong><i class="fa fa-info-circle"></i>&nbsp;No wiki data found for the selected countries</strong>
              </div>
            </div>
            <div v-else class="mt-3">
              <div class="wiki-data-not-found">
                <strong v-if="countriesMissingWikiData.length > 0">
                  <i class="fa fa-info-circle"></i>&nbsp;No wiki data found for countries: {{ countriesMissingWikiData }}
                </strong>
              </div>
              <div v-if="printStyles" class="alert alert-primary">
                <div class="row">
                  <div class="col-6">
                    <strong>Class of Business</strong>&nbsp;
                    <em>{{ classType }}</em>
                  </div>
                  <div class="col-6">
                    <strong>Countries Selected</strong>&nbsp;
                    <em>{{ $store.getters.getCountryList }}</em>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <strong>Policy Type</strong>&nbsp;
                    <em>{{ policyClass }}</em>
                  </div>
                </div>
              </div>
              <div class="table-responsive-xl">
              <!-- TODO: Configure this table in an array and pass to a component to programmatically render
                          We need to remove the logic from this component before it becomes more spaghettified -->
                <table class="table table-responsive table-bordered table-hover" :class="{'print-styles': printStyles}">
                  <thead>
                    <tr class="footable--group-row" v-if="hasExtraHeaderRow()">
                      <th data-group="group1" class="border-right"></th>
                      <th
                        data-group="group2"
                        colspan="3"
                        class="border-right text-center"
                      >
                        Coverage Types
                      </th>
                      <th
                        data-group="group3"
                        :colspan="usingCentralColletion() ? 5 : 4"
                        class="border-right text-center"
                      >
                        Local Requirements
                      </th>
                      <th data-group="group4"
                          :colspan="minimumRequirementsColumns().length"
                          data-breakpoints="ty xs sm md"
                          class="border-right"
                          v-if="usingMinimumRequirements()"
                          >
                        Minimum Requirements
                      </th>
                      <th data-group="group5" colspan="2" class="border-right" v-if="usingMaxPolicyLimit()"></th>
                    </tr>
                    <tr>
                      <th data-group="group1" class="border-right">
                        <span id="group1-tooltip">Country <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group1-tooltip"  v-if="showTooltips()">Country or Territory where insurance is required</b-tooltip>
                      </th>
                      <th data-group="group2" style="width: 90px;">
                        <span id="group2-tooltip">{{ getLocalPolicyLabel() }} <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group2-tooltip" v-if="showTooltips()">Is there capability for a Local (Admitted) policy to be issued in this territory</b-tooltip>
                      </th>
                      <th
                        data-group="group2"
                        style="width: 90px;"
                        data-breakpoints="ty xs sm"
                        v-if="usingFOSNonAdm()"
                      ><span id="group3-tooltip">Non-Admitted <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group3-tooltip" v-if="showTooltips()">Can cover for the risk be provided under the Master Policy</b-tooltip>
                      </th>
                      <th
                        data-group="group2"
                        style="width: 90px;"
                        class="border-right"
                        v-if="usingFOSNonAdm()"
                      ><span id="group4-tooltip">Freedom of Service <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group4-tooltip" v-if="showTooltips()">Can insurance be provided from an insurance local policy issued in another country within the EEA</b-tooltip>
                      </th>
                      <th data-group="group3">
                        <span id="group5-tooltip">Broker Required <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group5-tooltip" v-if="showTooltips()">Is a local broker required to arrange a local policy</b-tooltip>
                      </th>
                      <th data-group="group3" v-if="usingCentralColletion()">
                        <span id="group6-tooltip">Central Collection <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group6-tooltip" v-if="showTooltips()">Is it permitted to collect the premium for a local policy outside the country the policy is issued in</b-tooltip>
                      </th>
                      <th data-group="group3" data-breakpoints="ty xs sm">
                        <span id="group7-tooltip">Cash Before Cover <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group7-tooltip" v-if="showTooltips()">Must the premium be collected before the cover under the local policy can commence</b-tooltip>
                      </th>
                      <th data-group="group3">
                        <span id="group8-tooltip">Local Retention  <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group8-tooltip" v-if="showTooltips()">Will any share of the risk be retained in country by the local partner or reinsured to a local reinsurance company</b-tooltip>

                      </th>
                      <th data-group="group3" class="border-right">
                        <span id="group9-tooltip">Local Reinsurance  <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group9-tooltip" v-if="showTooltips()">Will any share of the risk be retained in country by the local partner or reinsured to a local reinsurance company</b-tooltip>
                      </th>
                      <th v-for="(col, idx) in minimumRequirementsColumns()"
                          data-group="group4"
                          data-breakpoints="ty xs sm md"
                          :key="col.heading"
                          >
                        <span :id="'group10-'+ idx + '-tooltip'">{{ col.heading }}  <i class="fa fa-info-circle" v-if="col.tooltip && showTooltips()"></i></span>
                        <b-tooltip v-if="col.tooltip && showTooltips()" :target="'group10-' + idx + '-tooltip'">{{ col.tooltip }}</b-tooltip>
                      </th>
                      <th data-group="group5" data-breakpoints="ty xs" v-if="usingPricingMatrix()">
                        <span id="group11-tooltip">Indicative Premium ({{ $store.getters.requestedCurrencyCode }}) <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group11-tooltip" v-if="showTooltips()">What is the range of premiums that can be applied for the local policy based on the Limit of Indemnity selected</b-tooltip>
                      </th>
                      <th data-group="group6" v-if="usingMaxPolicyLimit() && !usingMinimumRequirements()" class="border-left">
                        Policy Currency
                      </th>
                      <th data-group="group6" v-if="usingMaxPolicyLimit()">
                        <span id="group13-tooltip">Maximum Local Policy Limit ({{ $store.getters.requestedCurrencyCode }}) <i class="fa fa-info-circle" v-if="showTooltips()"></i></span>
                        <b-tooltip target="group13-tooltip" v-if="showTooltips()">What is the maximum policy limit the local partner can issue for their local policy</b-tooltip>
                      </th>
                      <th data-group="group7" v-if="isTMG()">
                        Manuscript Policies Permitted
                      </th>
                      <th data-group="group7" v-if="isTMG()" class="border-right">
                        Pre-Issuance Requirements
                      </th>
                    </tr>
                  </thead>
                  <tbody id="tblCapabilities">
                    <template
                        v-for="country in $store.getters.localPolicies"
                        >
                        <!-- TODO: Move the printStyles state to a more accessible place s.t. we don't have to pass it to any component that needs to react to it -->
                      <tr :key="country.options.country.value + 'a'" :class="{'doubleCapabilitiesRow': usingCountryPartnerInput()}">
                        <wiki-table-data
                          :wikiData="country.options.country"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryField="true"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          :wikiData="country.options.localPolicy"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          v-if="usingFOSNonAdm()"
                          :wikiData="country.options.nonAdmitted"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          v-if="usingFOSNonAdm()"
                          :wikiData="country.options.fos"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          :wikiData="country.options.brokerReq"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          v-if="usingCentralColletion()"
                          :wikiData="country.options.cetColl"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          :wikiData="country.options.cashBef"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          :wikiData="country.options.localRetention"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          :wikiData="country.options.localReinsurance"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data v-for="col in minimumRequirementsColumns(country)"
                          :wikiData="col.data"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :key="col.heading"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                            v-if="usingPricingMatrix()"
                            :wikiData="{
                            value: (() => {
                              if (country.options.quoteRequired && country.options.quoteRequired.value === 'Yes') {
                                return 'Quote Required';
                              } else if (country.options.indicativePremium && country.options.indicativePremium.premium_to) {
                                return $options.filters.formatCurrency(country.options.indicativePremium.premium_from, 6)
                                  + ' - '
                                  + $options.filters.formatCurrency(country.options.indicativePremium.premium_to, 6)
                              } else {
                                return '';
                              }
                              })(),
                            additional: (() => {
                              if (country.options.quoteRequired
                                && country.options.quoteRequired.value === 'Yes'
                                && country.options.indicativePremium
                                && country.options.indicativePremium.premium_to
                                ) {
                                let sigFigFrom = $options.filters.numsBeforeDecimal(country.options.indicativePremium.premium_from) + 2;
                                let sigFigTo = $options.filters.numsBeforeDecimal(country.options.indicativePremium.premium_to) + 2;
                                return $options.filters.formatCurrency(country.options.indicativePremium.premium_from, sigFigFrom)
                                    + ' - '
                                    + $options.filters.formatCurrency(country.options.indicativePremium.premium_to, sigFigTo);
                              } else {
                                return '';
                              }
                              })(),
                            level: country.options.indicativePremium ? country.options.indicativePremium.level : 'info'
                          }"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data v-if="usingMaxPolicyLimit() && !usingMinimumRequirements()" 
                          :wikiData="country.options.policyCurrency" 
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                          />
                        <wiki-table-data v-if="usingMaxPolicyLimit()" 
                          :wikiData="indicativeLimits(country)"
                          :sanctionSeverity="country.options.country.sanctionSeverity"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                          />
                        <wiki-table-data v-if="isTMG()"
                                         :wikiData="country.options.manusrPermit"
                                         :sanctionSeverity="country.options.country.sanctionSeverity"
                                         :countryId="country.options.country.country_id"
                                         :useAdditionalTooltip="true"
                                         :print-styles="printStyles"
                        />
                        <wiki-table-data v-if="isTMG()"
                                         :wikiData="country.options.preIss"
                                         :sanctionSeverity="country.options.country.sanctionSeverity"
                                         :countryId="country.options.country.country_id"
                                         :useAdditionalTooltip="true"
                                         :print-styles="printStyles"
                        />
                      </tr>
                      <tr v-if="usingCountryPartnerInput()" :key="country.options.country.value + 'b'" class="doubleCapabilitiesRow">
                        <td colspan="100">
                          <div class="row">
                            <div class="col-3">
                              <label>
                                Local Fronting Carrier / Network Partner Legal Name
                              </label>
                              <br />
                              <span v-if="!networkPartnerAvailable(country)" class="text-danger">Network Partner has not been determined therefore please allow for extra time.</span>
                              <hr v-if="!networkPartnerAvailable(country) && countrySanctioned(country)" />
                              <span v-if="countrySanctioned(country)" class="text-danger" v-html="countrySanctionedText(country)"></span>
                              <span v-if="countryAvailable(country) && !countrySanctioned(country)">{{ country.options.company.name }}</span>
                            </div>
                            <div class="col-3">
                              <label>
                                Affiliation
                              </label>
                              <br />
                              <span>{{ country.options.affiliation.value }}</span>
                            </div>
                            <div class="col-4" v-if="selectedWikiItem(country.options.country.country_id)">
                              <div class="wiki-additional"  
                                   :class="'capabilities-' + selectedWikiItem(country.options.country.country_id).level"
                                   style="width: 100%; height: 100%;padding: 4px;"
                                >
                                <label class="text-dark">
                                  <span v-if="selectedWikiItemName(country.options.country.country_id)">{{ selectedWikiItemName(country.options.country.country_id) }} - </span>Additional Commentary:
                                </label>
                                <br />
                                <em>{{ selectedWikiItemAdditional(country.options.country.country_id) }}</em>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="row" v-if="!usingSingleDetailsPane()">
      <div class="col" v-if="showDetailsAndSaveElements" id="capabilitiesUserData">
        <h5 v-if="!inquiryOnlyMode">Save Report or Progress Local Placement</h5>
        <p v-if="!inquiryOnlyMode">Saving the report enables you to retrieve it at a later date</p>
        <p v-if="!inquiryOnlyMode">
          Instructing placement will alert your local Network Co-ordinator to
          commence the local policy quotes (where required)
        </p>
        <div v-for="(fieldConfig, field) in activeFields" :key="field">
          <div class="form-group row"
            v-if="fieldConfig.type==inputTypes.TEXT || fieldConfig.type==inputTypes.EMAIL"
            >
            <!-- TODO: Add printStyles versions of these inputs - just use hidden <em>s? -->
            <label :for="field" class="col-sm-2 col-form-label" :class="{'print-styles': printStyles}">
              {{ fieldConfig.label }}<strong v-if="fieldConfig.required" :class="{'d-none': printStyles}">&nbsp;*</strong>
            </label>
            <div class="col-sm-4">
              <input
                :id="field"
                :type="fieldConfig.type"
                :placeholder="'Insert ' + fieldConfig.label"
                autocomplete="off"
                :value="$store.getters[field]"
                @input="handleInput($event.target.value, field)"
                class="form-control"
                :class="{'d-none': printStyles}"
              />
              <em :class="{'d-none': !printStyles}">{{ $store.getters[field] }}</em>
            </div>
          </div>
          <div class="form-group row" v-if="fieldConfig.type==inputTypes.DATE">
            <label :for="field" class="col-sm-2 col-form-label" :class="{'print-styles': printStyles}"
              >{{ fieldConfig.label }}<strong v-if="fieldConfig.required" :class="{'d-none': printStyles}">&nbsp;*</strong></label
            >
            <div class="col-sm-4">
              <date-picker
                :id="field"
                :value="inceptionDate"
                placeholder="fieldConfig.label"
                :monday-first="true"
                format="dd MMM yyyy"
                @input="value => sendInput(value, field)"
                input-class="date-picker-input"
                :class="{'d-none': printStyles}"
              ></date-picker>
              <em :class="{'d-none': !printStyles}">{{ inceptionDate }}</em>
            </div>
          </div>
          <div class="form-group row"
            v-if="fieldConfig.type==inputTypes.PARTNER && usingPartnerDropdown()"
            >
            <label for="partnerName" class="col-sm-2 col-form-label" :class="{'print-styles': printStyles}"
              >Partner Name<strong v-if="fieldConfig.required" :class="{'d-none': printStyles}">&nbsp;*</strong></label>
            <div class="col-sm-4">
              <v-select
                  id="partnerName"
                  placeholder="Select Partner Name"
                  @input="value => sendInput(value, 'partnerName')"
                  @search="getPartnersList"
                  :options="partnersList"
                  :clearable="false"
                  :class="{'d-none': printStyles}"
                  >
              </v-select>
              <em :class="{'d-none': !printStyles}">{{ $store.getters.partnerName }}</em>
            </div>
          </div>
          <div class="form-group row"
            v-if="fieldConfig.type==inputTypes.CLIENT && usingClientAutoComplete()"
            >
            <label for="clientName" class="col-sm-2 col-form-label" :class="{'print-styles': printStyles}"
              >{{ fieldConfig.label }}<strong v-if="fieldConfig.required" :class="{'d-none': printStyles}">&nbsp;*</strong></label>
            <div class="col-sm-4">
              <input
                  id="clientName"
                  placeholder="Client (Account) Name"
                  @input="handleInput($event.target.value, 'clientName')"
                  :value="$store.getters.clientName"
                  class="form-control"
                  :class="{'d-none': printStyles}"
              />
              <em :class="{'d-none': !printStyles}">{{ $store.getters.clientName }}</em>
            </div>
            <div class="col-sm-4" v-if="usingClientAutoComplete">
              <sounds-like :current="$store.getters.clientName" 
                           :list="clientsList" 
                           itemKey="client_id"
                           itemText="client_name"
                           itemLabel="Clients"
                           @select="value => handleInput(value, 'clientName')"/>
            </div>
          </div>
        </div>
        <small class="text-muted" :class="{'d-none': printStyles}"><em>* Required data</em></small>
        <br />
        <br />
      </div>
    </div>
    <wiki-additional-comments v-show="printStyles" />
    <b-alert show variant="info" v-if="shouldShowCapabilitiesBanner()">
      <i class="fa fa-info-circle fa-2x"></i>&nbsp;The information provided herein is preliminary, subject to change and not binding. 
      It is provided to aid in understanding basic information about the countries involved and should not be relied
      on as representations or warranties of any kind.
      <small :class="{'d-none': !printStyles}"><em>Data accurate as of {{ nowPrettyFormatted }}</em></small>
    </b-alert>
    <inquiry-actions v-if="!printStyles" :swapSaveAndSlot="capabilitiesPDFExportEnabled() && inquiryOnlyMode && canSaveReport">
      <button v-if="!inquiryOnlyMode"
        class="btn btn-success float-right"
        :disabled="saving || !$store.getters.programmeInstructionsActive || $store.getters.localPolicies.length === 0"
        @click="goToProgrammeInstructions"
      >
        <i class="fa fa-paper-plane"></i>&nbsp; {{ getInstructCoordinatorLabel() }}
      </button>
      <button v-if="capabilitiesPDFExportEnabled()"
        class="btn btn-success"
        :class="{'float-right': !inquiryOnlyMode || !canSaveReport}"
        @click="() => {this.generatingPDF = true; exportCapabilities()}"
      >
        <span :class="{'d-none': !generatingPDF}">
          <i class="fa fa-sync fa-spin"></i>
        </span>
        <span :class="{'d-none': generatingPDF}">
          <i class="fa fa-download"></i>
        </span>
        &nbsp;Create PDF Output
      </button>
    </inquiry-actions>
    <OTPLogin v-if="showOTPLogin" />
    <login-modal
      v-if="!$store.getters.isLoggedIn"
      :email="$store.getters.emailAddress"
      subtitle="Please login before you save"
      login-button-text="Login and Save"
      @logged-in="saveReport"
    ></login-modal>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import WikiTableData from "./WikiTableData";
import WikiAdditionalComments from "./WikiAdditionalComments";
import TooltipButton from "./TooltipButton";
import LoginModal from "./LoginModal";
import OTPLogin from "./OTPLogin";
import FeaturesMixin, { inputTypes } from "@/mixins/features.mixin";
import SanctionsMixin from "@/mixins/sanctions.mixin";
import DateService from "@/services/date.service";
import InquiryActions from "./InquiryActions";
import ScaledImage from "./ScaledImage";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import html2pdf from "html2pdf.js";
import SoundsLike from "./SoundsLike";

export default {
  name: "CapabilitiesChecker",
  props: {
    capabilitiesData: Array,
    capabilitiesActive: Boolean
  },
  components: {
    WikiTableData,
    TooltipButton,
    LoginModal,
    InquiryActions,
    ScaledImage,
    WikiAdditionalComments,
    SoundsLike,
    OTPLogin
  },
  mixins: [FeaturesMixin, SanctionsMixin],
  computed: {
    inceptionDate() {
      let inDate = this.$store.getters.inceptionDate;
      if (!inDate) {
        return "";
      }
      inDate = new Date(inDate);
      return new Date(inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .split("T")[0];
    },
    countriesMissingWikiData() {
      let localPolicies = this.$store.getters.localPolicies;
      return this.$store.getters.getSelectedCountries.filter(
        country => {
          return !localPolicies.find(
            localPolicy =>
              localPolicy.options.country.country_id === country.country_id
          );
        }
      ).map(country => country.country_name)
      .join(', ');
    },
    activeFields() {
      return this.activeCapabilitiesFields();
    },
    classType() {
      return this.$store.getters.classType ? this.$store.getters.classType.policy_class_type_caption : '';
    },
    policyClass() {
      return this.$store.getters.policyClass ? this.$store.getters.policyClass.policy_class_caption : '';
    },
    nowPrettyFormatted() {
      return DateService.nowPrettyFormatted();
    },
  },
  data: () => {
    return {
      partnersList: [],
      clientsList: [],
      saving: false,
      devMode: process.env.NODE_ENV !== "production",
      inputTypes,
      showPDFElements: false,
      generatingPDF: false,
      printStyles: false,
    };
  },
  methods: {
		// TODO: Refactor me - This makes the input boxes feel really sluggish, as it's saving to the Vuex store with every character input
    sendInput(value, type) {
      const payload = {};
      payload[type] = value;
      this.$store.dispatch("setPolicyDetail", payload);
    },
    async getPartnersList() {
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/partner";

      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        this.partnersList = [...new Set(data.map(function(value) {
          return value.partner_name;
        }))];
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    async getClientsList(force = false) {
      if (this.clientsList.length > 0 && !force) {
        return;
      }
      try {
        let { data } = await ApiService.get('client');
        this.clientsList = data;
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    isTMG() {
      return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
    },
    goToProgrammeInstructions() {
      this.$router.push({ name: "Programme Instructions" });
    },
    setProgrammeName(value) {
      this.$store.commit("setProgrammeName", {name: value});
    },
    handleInput(value, inputType) {
      if (inputType === "programmeName") {
        this.setProgrammeName(value);
      } else {
        this.sendInput(value, inputType);
      }
    },
    handleCountryInput(countryId, field, value) {
      this.$store.dispatch(
        'updatePolicyValue',
        {
          countryId,
          coverId: '',
          type: field,
          value
        }
      );
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Programmes" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    },
    async exportCapabilities() {
      this.generatingPDF = true;
      let opt = {
        margin: 3,
        image: {type: "jpeg", quality: 0.98},
        filename: "iCede Inquiry Tool - Inquiry Export", // Add Date to filename
        html2canvas: {scale: 2.},
        pagebreak: { avoid: ["tr", ".alert", "div.row"] },
        jsPDF: { orientation: "l" },
      };
      let element = document.getElementById('capabilities-checker');
      const context = this;
      this.printStyles = true;
      html2pdf().set(opt).from(element).save().then(() => {context.generatingPDF = false; context.printStyles = false;});
    },
    minimumRequirementsColumns(country) {
        if (!this.usingMinimumRequirements()) {
            return [];
        }

        if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO)) {
          let aColumns = [
              {heading: "Pre-Issue Documents Required (KYC, AML, Other)", data: country?.options?.preIssueDocuments},
              {heading: "Policy Currency", data: country?.options.policyCurrency},
              {heading: "Tariff or Local Rating Req'd", data: country?.options.tariffRatingApply},
              {
heading: "Minimum Policy Premium (USD)",
         data: country?.options.minPremium,
              },
          ];
          return aColumns;
        }
        if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG)) {
          return [
            {heading: "Enforce Before", data: country?.options.enforceBefore, tooltip: 'At what stage is the decripbed Required Instructions needed by the local partner'},
            {
              heading: "Required Instructions",
              data: {
                value: country?.options.minimumRequirements.value.join(', '),
                level: country?.options.minimumRequirements.level
              },
              tooltip: 'Details of what documentation or other information the local partner requires to support quotation or policy issuance'
            },
            {heading: "Policy Currency", data: country?.options.policyCurrency, tooltip: ''},
          ];
        }
        return [
            {heading: "Enforce Before", data: country?.options.enforceBefore},
            {
              heading: "Required Instructions",
              data: {
                value: country?.options.minimumRequirements.value.join(', '),
                level: country?.options.minimumRequirements.level
              }
            },
            {heading: "Policy Currency", data: country?.options.policyCurrency},
        ];

    },
    showTooltips() {
      return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
    },
    networkPartnerAvailable(country) {
      return (!country.options.affiliation || country.options.affiliation.available);
    },
    countryAvailable(country) {
      return !this.countrySanctioned(country) && this.networkPartnerAvailable(country);
    },
    countrySanctioned(country) {
      return country.options.country.sanctionSeverity != 0;
    },
    countrySanctionedText(country) {
      return this.getSanctionMessage(country.options.country.sanctionSeverity);
    },
    selectedWikiItem(countryId) {
      const localPolicy = this.$store.getters.localPolicies.find(policy => policy.options.country.country_id == countryId);
      if (!localPolicy) {
        return null;
      }
      return localPolicy.selectedWikiItem;
    },
    selectedWikiItemName(countryId) {
      const item = this.selectedWikiItem(countryId);
      if (!item || !item.feature_name) {
        return '';
      }
      return item.feature_name;
    },
    selectedWikiItemAdditional(countryId) {
      const item = this.selectedWikiItem(countryId);
      if (!item || !item.additional) {
        return '';
      }
      return item.additional;
    },
    indicativeLimits(country) {
      return country.options.indicativeLimits ?? {level: "success", value: "", id: "indicativeLimits" + country.options.country.country_id};
    },
  },
  mounted() {
    // Redirect to the country selection tab if we don't have the data for this tab
    if (!this.capabilitiesActive && !this.$store.getters.isLoadingWikiData) {
      this.$router.replace({ name: "Country Select" });
    }

    if (this.usingAMENAElements()) {
      this.getPartnersList();
    }

    if (this.usingClientAutoComplete()) {
      this.getClientsList(true);
    }

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });

    document.getElementById("myTab").scrollIntoView({ behavior: "smooth" });
  }
};
</script>
<style scoped>
.capabilities-success {
  background-color: #52de73 !important;
}
.capabilities-danger {
  background-color: #ed616f !important;
  font-weight: bold;
}
.capabilities-warning {
  background-color: #fcc930 !important;
}
.table.b-table {
  height: 1200px;
}
div.hide {
  display: none;
}
.wiki-data-not-found {
  font-size: 14pt;
  margin-bottom: 14px;
}
input.form-control.primary-office {
  user-select: none;
  width: 600px;
  background-color: #FFFFFF;
}
/* Striped rows when using two `tr`s for each country to keep the wiki data and partner input visually grouped */
.doubleCapabilitiesRow:nth-child(4n+1), .doubleCapabilitiesRow:nth-child(4n+2) {
  background-color: #F5F5F5;
}
table.print-styles tr th {
  padding: 0 0 2px 2px;
  font-size: 0.6em;
}
table.print-styles tr td {
  padding: 0 0 0 2px;
  font-size: 0.6em;
}
table.print-styles tr td label {
  font-size: 1em;
}
label.print-styles {
  font-size: 0.6em;
}
#capabilitiesUserData em {
  font-size: 0.8em;
}

/* Make the table header sticky */
table.table-responsive thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
table.table-responsive thead th {
  background-color: #FFFFFF;
}
</style>
